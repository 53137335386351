.verified {
    width: 150px;
    height: 150px;
}

.verified img {
    width: 100%;
    height: 100%;
}

.about-us-company {
    background: linear-gradient(90deg, rgba(213, 255, 228, 0.8), rgba(213, 255, 228, 0.8)), url('../../assets/background/company.jpg') no-repeat center center / cover;
}

.card-header-title {
    width: 100px;
    height: 100px;
}

.card-header-title img {
    width: 100%;
    height: 100%;
}