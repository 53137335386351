.banner-card {
    z-index: 99;
    background-color: rgba(213, 255, 228, 0.3) !important;
}

.carouselOne {
    height: calc(100vh - 106px);
    text-align: justify;
    background:
        linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        url('../../assets/background/banner-carousel-one.jpg') no-repeat center center / cover;
}

.carouselTwo {
    height: calc(100vh - 106px);
    background:
        linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        url('../../assets//background/banner-carousel-two.jpg') no-repeat top center / cover;
}

.carouselThree {
    height: calc(100vh - 106px);
    background:
        linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        url('../../assets/background/banner-carousel-three.jpg') no-repeat center center / cover;
}