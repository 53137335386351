body,
*::before,
*::after {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}

:root {
    --primary: #1E6F5C;
    --secondary: #D5FFE4;
    --warning: #FFFEC4;
    --white: #FFFFFF;
}

a,
a:hover {
    color: var(--primary) !important;
    text-decoration: none !important;
}

/* Bootstrap Overrides */

.text-primary {
    color: var(--primary) !important;
}

.text-warning {
    color: var(--warning) !important;
}

.text-secondary {
    color: var(--secondary) !important;
}

.bg-light {
    background-color: var(--secondary) !important;
}

.bg-warning {
    background-color: var(--warning) !important;
}

.btn-outline-primary {
    color: var(--secondary) !important;
    border: 1px solid var(--secondary) !important;
    background-color: transparent !important;
    transition: all 0.5s ease-in-out !important;
}

.btn-outline-primary:hover {
    color: var(--primary) !important;
    border: 1px solid var(--secondary) !important;
    background-color: var(--secondary) !important;
}

.btn-outline-secondary {
    color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
    background-color: transparent !important;
    transition: all 0.5s ease-in-out !important;
}

.btn-outline-secondary:hover {
    color: var(--secondary) !important;
    border: 1px solid var(--primary) !important;
    background-color: var(--primary) !important;
}

.accordion,
.accordion-item {
    border: none !important;
    border-radius: 0% !important;
}

.accordion-button {
    font-weight: 600;
    color: var(--primary) !important;
    border: none !important;
    border-radius: 0% !important;
}

.accordion-button::after {
    content: none !important;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-button:not(.collapsed),
.accordion-body {
    background-color: var(--secondary) !important;
}

.form-control {
    color: var(--primary) !important;
    border-color: #000000 !important;
    background: transparent;
    border-width: 2px;
    transition: all 0.5s ease-in-out;
}

.form-control:focus {
    color: var(--primary) !important;
    border-color: var(--primary) !important;
    background: transparent;
    border-width: 2px;
}

.modal-content {
    border: none !important;
    border-radius: 0 !important;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Common CSS */

.earth-icon {
    width: 100px;
    height: 100px;
}

.earth-icon img {
    width: 100%;
    height: 100%;
}

.section-heading {
    position: relative;
}

.section-heading::before {
    content: '';
    position: absolute;
    width: 100px;
    height: 2px;
    background-color: var(--primary);
    top: -20%;
    right: 50%;
}

.section-heading::after {
    content: '';
    position: absolute;
    width: 100px;
    height: 2px;
    background-color: var(--primary);
    bottom: -20%;
    left: 50%;
}