.footer-topbar-container{
    background: linear-gradient(90deg, rgba(213, 255, 228, 0.5), rgba(213, 255, 228, 0.5)), url('../../assets/background/footer-topbar.jpg') no-repeat center center / cover;
}

.socil-icons {
    border: none;
    background-color: var(--primary) !important;
    border: 2px solid var(--primary);
    transition: all 0.5s ease-in-out;
}

.socil-icons svg {
    color: white !important;
    transition: all 0.5s ease-in-out;
}

.socil-icons:hover{
    border: 2px solid var(--primary);
    background-color: transparent !important;
}

.socil-icons:hover svg{
    color: var(--primary) !important;
}

.nav-item a {
    transition: all 0.5s ease-in-out;
}

.card-text .nav .nav-item a:hover {
    transform: translateX(10px);
}

.card-title{
    position: relative;
}

.card-title::after {
    content: '';
    position: absolute;
    bottom: 10%;
    left: 0%;
    width: 70px;
    height: 2px;
    background-color: var(--primary);
}