.portfolio-card {
    min-height: 317px;
}

.fruits {
    background: linear-gradient(90deg, rgba(213, 255, 228, 0.8), rgba(213, 255, 228, 0.8)), url('../../assets/icons/fruits.png') no-repeat center center / cover;
}

.vegetables {
    background: linear-gradient(90deg, rgba(213, 255, 228, 0.8), rgba(213, 255, 228, 0.8)), url('../../assets/icons/vegetable.png') no-repeat center center / cover;
}

.dry-fruits {
    background: linear-gradient(90deg, rgba(213, 255, 228, 0.8), rgba(213, 255, 228, 0.8)), url('../../assets/icons/dried-fruits.png') no-repeat center center / cover;
}

.spices {
    background: linear-gradient(90deg, rgba(213, 255, 228, 0.8), rgba(213, 255, 228, 0.8)), url('../../assets/icons/spice.png') no-repeat center center / cover;
}

.flour {
    background: linear-gradient(90deg, rgba(213, 255, 228, 0.8), rgba(213, 255, 228, 0.8)), url('../../assets/icons/wheat-flour.png') no-repeat center center / cover;
}

.pulses {
    background: linear-gradient(90deg, rgba(213, 255, 228, 0.8), rgba(213, 255, 228, 0.8)), url('../../assets/icons/red-beans.png') no-repeat center center / cover;
}