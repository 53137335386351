.product-card{
    cursor: pointer;
}

.product-image {
    height: 500px;
}

.product-image img {
    width: 100%;
    height: 100%;
}