.logo {
    width: 150px;
    height: 80px;
}

.logo img {
    width: 100%;
    height: 100%;
}

.menu {
    position: relative;
    animation: fade 0.5s linear forwards;
}

.menu::before {
    content: '';
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 0%;
    height: 7%;
    background-color: var(--primary);
    transition: all 0.5s ease-in-out;
}

.menu::after {
    content: '';
    position: absolute;
    top: 0%;
    right: 0%;
    width: 0%;
    height: 7%;
    background-color: var(--primary);
    transition: all 0.5s ease-in-out;
}

.menu:hover::before,
.menu:hover::after {
    width: 100%;
}

.sub-menu-dropdown {
    display: none !important;
    text-align: left;
}

.dropdown-menu:hover .sub-menu-dropdown {
    display: flex !important;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0%;
    opacity: 0;
    animation: subMenuAnimation 0.5s linear forwards;
}

.sub-menu {
    transition: all 0.5s ease-in-out;
}

.sub-menu:hover {
    transform: translateX(10px);
}

@keyframes subMenuAnimation {
    from {
        opacity: 0;
        visibility: hidden;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}

@media screen and (max-width: 768px) {
    .sub-menu-dropdown {
        padding-left: 25px;
    }

    .dropdown-menu:hover .sub-menu-dropdown {
        position: static;
    }

    .menu::before,
    .menu::after {
        height: 0px;
    }
}